<template>
  <v-container id="data-tables-view" fluid tag="section">
    <v-col class="text-right">
      <app-btn @click="dialog = true">
        <v-icon>mdi-plus</v-icon> Add New Group
      </app-btn>
    </v-col>
    <material-card
      icon="mdi-message-processing-outline"
      icon-small
      color="primary"
      title="Message Groups"
    >
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          label="Search records"
          single-line
          style="max-width: 250px"
        />

        <v-divider class="mt-3" />

        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          item-key="messageGroupId"
          show-expand
          :expanded.sync="expanded"
          :items-per-page="10"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': [5, 10, 15],
          }"
        >
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="VehicleTypeForm">
                      <v-row>
                        <v-col cols="12" class="mt-3">
                          <v-text-field
                            v-model="editedItem.name"
                            label="Group name"
                            :rules="[rules.required]"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogMessage" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-form>
                      <v-row>
                        <v-col cols="12" class="mt-3">
                          <v-text-field v-model="message" label="Message" />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogMessage = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="sendMessage()">
                    Send
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogAddUser" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="VehicleTypeForm">
                      <v-row>
                        <v-col cols="12" class="mt-3">
                          <v-text-field
                            v-model="editedItem.name"
                            label="Group name"
                            :rules="[rules.required]"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeDelete">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              medium
              color="info"
              class="mr-2"
              @click="
                group = item;
                dialogMessage = true;
              "
            >
              mdi-send
            </v-icon>
            <!-- <v-icon medium color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium color="error" @click="deleteItem(item)">
              mdi-delete
            </v-icon> -->
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="primary pa-1">
              <MessageGroup
                :groupId="item.messageGroupId"
                :groupName="item.name"
              />
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
import MessageGroup from "./components/GroupMessage.vue";
import moment from "moment";
export default {
  name: "DataTablesView",
  components: {
    MessageGroup,
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },
    dialog: false,
    dialogDelete: false,
    dialogAddUser: false,
    dialogAddDevice: false,
    dialogMessage: false,
    expanded: [],
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Actions", align: "end", value: "actions", sortable: false },
    ],
    items: [],
    totalItems: 0,
    loading: false,
    options: {},
    editedIndex: -1,
    editedItem: {
      name: "",
    },
    defaultItem: {
      name: "",
    },
    addUserItem: {
      groupName: "",
      user: "",
    },
    defaultUserItem: {
      groupName: "",
      user: "",
    },
    search: "",
    searchTimeout: null,
    group: null,
    message: "",
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Group" : "Edit Group";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        if (!this.options.isSearch) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
    search: {
      handler() {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          if (this.search) {
            this.getDataFromApi();
          }
        }, 2000);
        this.completedSearch = "";
      },
    },
  },

  methods: {
    getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.$http
        .post("/MessageGroups/Paginated", {
          pageNumber: this.page,
          pageSize: this.itemsPerPage,
        })
        .then((response) => {
          this.items = response?.data?.data?.items || [];
          this.totalItems = response?.data?.data?.totalCount || 0;
        })
        .catch(() => {
          this.items = [];
          this.totalItems = 0;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    editItem(item) {
      this.editedIndex = this.vehicleTypes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.vehicleTypes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let index = this.editedIndex;
      this.$http
        .delete(`/VehicleType/${this.editedItem.vehicleTypeId}`)
        .then((response) => {
          if (response.data.success) {
            this.vehicleTypes.splice(index, 1);
            this.$toast.success(
              "VehicleType deleted successfully.",
              "Success",
              {
                position: "topRight",
              }
            );
          } else {
            this.$toast.error("Something went wrong.", "Error", {
              position: "topRight",
            });
          }
        })
        .catch(() => {});
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      let index = this.editedIndex;
      if (this.$refs.VehicleTypeForm.validate()) {
        if (index > -1) {
        } else {
          this.$http
            .post("/MessageGroups", this.editedItem)
            .then((response) => {
              if (response.data.success) {
                this.$toast.success(response.data.message, "Success", {
                  position: "topRight",
                });
                this.items.unshift(response.data.data);
              } else {
                this.$toast.error(response.data.message, "Error", {
                  position: "topRight",
                });
              }
            })
            .catch(() => {});
        }
        this.close();
      }
    },

    sendMessage() {
      let group = this.group;
      this.$http
        .post(`/MessageGroups/${group.messageGroupId}/SendMessage`, {
          message: this.message,
          sentTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
        })
        .then((response) => {
          if (response.data.success) {
            this.$toast.success(response.data.message, "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        });
      this.dialogMessage = false;
      this.group = null;
    },
  },
};
</script>
