<template>
  <div style="background: white">
    <div class="text-right">
      <app-btn
        class="secondary ma-1"
        @click="
          dialogAddDevice = true;
          getDevices();
        "
      >
        <v-icon>mdi-plus</v-icon> Add New Device
      </app-btn>
      <app-btn
        class="secondary ma-1"
        @click="
          dialogAddUser = true;
          getUsers();
        "
      >
        <v-icon>mdi-plus</v-icon> Add New User
      </app-btn>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :items-per-page="10"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [5, 10, 15],
      }"
    >
      <template v-slot:top>
        <v-dialog v-model="dialogAddDevice" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Add new device</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form>
                  <v-row>
                    <v-col cols="12" class="mt-3">
                      <v-autocomplete
                        v-model="selectedDevices"
                        :items="devices"
                        chips
                        small-chips
                        item-text="vehicleNo"
                        item-value="id"
                        label="Devices"
                        multiple
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                @click="dialogAddDevice = false"
              >
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="addDevices">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAddUser" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Add new user</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form>
                  <v-row>
                    <v-col cols="12" class="mt-3">
                      <v-autocomplete
                        v-model="selectedUsers"
                        :items="users"
                        chips
                        small-chips
                        item-text="userName"
                        item-value="id"
                        label="Users"
                        multiple
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="dialogAddUser = false">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="addUsers"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="dialogDelete = false">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                OK
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <!-- <v-icon medium color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon> -->
        <!-- <v-icon medium color="error" @click="deleteItem(item)">
            mdi-delete
          </v-icon> -->
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  name: "GroupMessage",
  props: ["groupId", "groupName"],
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },
    dialogDelete: false,
    dialogDeleteUser: false,
    dialogDeleteDevice: false,
    dialogAddUser: false,
    dialogAddDevice: false,
    loading: false,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "userName",
      },
      {
        text: "Vehicle#",
        sortable: false,
        value: "vehicleNo",
      },

      { text: "Actions", align: "end", value: "actions", sortable: false },
    ],
    users: [],
    devices: [],
    selectedUsers: [],
    selectedDevices: [],
    items: [],
  }),
  created() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      if (this.groupId > 0) {
        this.loading = true;
        this.$http
          .get("/MessageGroups", {
            params: {
              messageGroupId: this.groupId,
            },
          })
          .then((response) => {
            let users = response?.data?.data?.users || [];
            let devices = response?.data?.data?.devices || [];
            this.items = [...users, ...devices];
          })
          .catch(() => {
            this.items = [];
          })
          .finally(() => (this.loading = false));
      }
    },

    getUsers() {
      if (!(this.users && this.users.length > 0)) {
        this.$http.get("/User").then((res) => {
          this.users = res.data;
        });
      }
    },

    getDevices() {
      if (!(this.devices && this.devices.length > 0)) {
        this.$http.get("/Device/GetDevicesFromQueue").then((res) => {
          this.devices = res.data;
        });
      }
    },

    addDevices() {
      let selectedDevices = this.selectedDevices;
      this.$http
        .post(`/MessageGroups/${this.groupId}/Devices`, {
          groupName: this.groupName,
          devices: selectedDevices,
        })
        .then((response) => {
          if (response.data.success) {
            this.$toast.success(response.data.message, "Success", {
              position: "topRight",
            });
            this.items = [...this.items, ...(response?.data?.data || [])];
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        });
      this.selectedDevices = [];
      this.dialogAddDevice = false;
    },
    addUsers() {
      let selectedUsers = this.selectedUsers;
      this.$http
        .post(`/MessageGroups/${this.groupId}/Users`, {
          groupName: this.groupName,
          users: selectedUsers,
        })
        .then((response) => {
          if (response.data.success) {
            this.$toast.success(response.data.message, "Success", {
              position: "topRight",
            });
            this.items = [...this.items, ...(response?.data?.data || [])];
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        });
      this.selectedUsers = [];
      this.dialogAddUser = false;
    },
    deleteItemConfirm() {},
  },
};
</script>
